import React from 'react'
import { BLOCKS } from '@contentful/rich-text-types'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import {
	HeroSection,
	HeroContainer,
	HeroDetails,
	Title,
	TitleSubText,
	Paragraph,
} from './styles'

const Hero = ({ seoTitle, seoDescription, body, sources }) => {
	const options = {
		renderNode: {
			[BLOCKS.PARAGRAPH]: (node, children) => {
				return <Paragraph>{children}</Paragraph>
			},
		},
	}
	return (
		<HeroSection sources={sources}>
			<HeroContainer sources={sources}>
				<HeroDetails>
					<Title>{seoTitle}</Title>
					<TitleSubText>{seoDescription}</TitleSubText>
					{documentToReactComponents(body.json, options)}
				</HeroDetails>
			</HeroContainer>
		</HeroSection>
	)
}

export default Hero
