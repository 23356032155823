import React from 'react'
import { gql } from 'apollo-boost'
import { graphql, useStaticQuery } from 'gatsby'
import { useQuery } from '@apollo/react-hooks'
import { Client } from '../../apollo/client'
import Spinner from '../utils/spinner'
import { BLOCKS, MARKS } from '@contentful/rich-text-types'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import filterLocale from '../utils/filter-locale'
import {
	NullText,
	Bold,
	DetailTitle,
	DetailText,
	UnorderedList,
	ValuesImage,
	ValuesContainer,
	Value,
	ValueImg,
	ValueText,
	ValuesTitle,
	MissionContainer,
	MissionTitle,
	MissionText,
} from './styles'

const APOLLO_GET_DETAILS = gql`
	query btnDetailsQuery($slug: String!, $locale: String!) {
		pageCollection(where: { slug: $slug }, locale: $locale) {
			items {
				seoTitle
				body {
					json
				}
			}
		}
	}
`

const ButtonDetails = ({ slug, locale }) => {
	const { value } = useStaticQuery(graphql`
		query featuredValuesQuery {
			value: allContentfulFeaturedItems(
				filter: { contentful_id: { eq: "bGCuKcyAkiKs3MhU89nik" } }
			) {
				edges {
					node {
						node_locale
						title
						items {
							name
							image {
								file {
									url
								}
								description
							}
						}
					}
				}
			}
		}
	`)
	const historyOptions = {
		renderMark: {
			[MARKS.BOLD]: text => <Bold>{text}</Bold>,
		},
		renderNode: {
			[BLOCKS.PARAGRAPH]: (node, children) => (
				<DetailText>{children}</DetailText>
			),
			[BLOCKS.HEADING_2]: (node, children) => (
				<DetailTitle>{children}</DetailTitle>
			),
			[BLOCKS.UL_LIST]: (node, children) => (
				<UnorderedList>{children}</UnorderedList>
			),
		},
	}

	const MissionOptions = {
		renderNode: {
			[BLOCKS.PARAGRAPH]: (node, children) => (
				<MissionText>{children}</MissionText>
			),
		},
	}

	const { loading, error, data } = useQuery(APOLLO_GET_DETAILS, {
		client: Client,
		variables: { slug: slug, locale: locale },
	})

	if (loading) {
		return <Spinner />
	}
	if (error || data.pageCollection.items.length < 1) {
		return <NullText>Something Went Wrong!</NullText>
	}

	const {
		pageCollection: {
			items: [{ seoTitle, body }],
		},
	} = data

	// const locale = 'en-AU'
	const [{ node }] = filterLocale(value.edges, locale)

	const { items, title } = node

	return slug === 'our-history' ? (
		<>{documentToReactComponents(body.json, historyOptions)}</>
	) : slug === 'our-values' ? (
		<>
			<ValuesTitle>{title}</ValuesTitle>
			<ValuesImage
				src={
					'https://images.ctfassets.net/ftcnv5up9dxv/7fXq67AdLgKW0MCJAxYzNA/8dee9c1b105f82eee6f30681297d41dd/Values_Tree.png'
				}
				alt=""
			/>
			<ValuesContainer>
				{items.map((item, i) => {
					return (
						<Value key={i}>
							<ValueImg>
								<img src={item.image.file.url} alt={item.image.description} />
							</ValueImg>
							<ValueText>{item.name}</ValueText>
						</Value>
					)
				})}
			</ValuesContainer>
		</>
	) : slug === 'our-mission' ? (
		<>
			<MissionTitle>{seoTitle}</MissionTitle>
			<MissionContainer>
				{documentToReactComponents(body.json, MissionOptions)}
			</MissionContainer>
		</>
	) : (
		''
	)
}

export default ButtonDetails
