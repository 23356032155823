import React, { useState, useEffect } from 'react'
import ButtonDetails from './button-details'
import {
	ToggleButtonSection,
	Button, 
	BtnDetailsSection,
	BtnDetailsContainer, } from './styles'

const ToggleButton = ({ locale }) => {
	const buttonDetails = [
		{
			buttonText: 'Maytronics History',
			slug: 'our-history',
			active: false,
		},
		{
			buttonText: 'Our Values',
			slug: 'our-values',
			active: false,
		},
		{
			buttonText: 'Our Mission',
			slug: 'our-mission',
			active: false,
		},
	]

	const [activeBtn, setActiveBtn] = useState(buttonDetails)
	const [activeSlug, setActiveSlug] = useState(buttonDetails[0].slug)
	const [sessionSlug, setCurrentSessionSlug] = useState(buttonDetails[0].slug)
	const [split, setSplit] = useState([])

	const activeBtnHandler = (index, slug) => {
		let active = activeBtn.map((x, i) => {
			return i === index ? { ...x, active: true } : { ...x, active: false }
		})
		setActiveSlug(slug)
		setActiveBtn(active)
	}

	useEffect(() => {
		const windowHash = window.location.hash
		const splitWindowHash = windowHash.split('#').join('')
		setSplit(splitWindowHash)
	})

	useEffect(() => {
		if (split === '') {
			sessionStorage.setItem('data', activeSlug)
			setCurrentSessionSlug(activeSlug)
			const firstBtnActive = { ...activeBtn[0], active: true }
			const btns = [...activeBtn]
			btns[0] = firstBtnActive
			setActiveBtn(btns)
		} else if (split != '') {
			let xx = activeBtn.map(x => {
				return x.slug === split
					? { ...x, active: true }
					: { ...x, active: false }
			})
			setActiveBtn(xx)
			sessionStorage.setItem('data', split)
			setCurrentSessionSlug(split)
		}

		return () => {
			sessionStorage.removeItem('data')
		}
	}, [activeSlug, split])

	return (
		<>
			<ToggleButtonSection>
				{activeBtn.map((btn, i) => {
					return (
						<Button
							onClick={e => {
								activeBtnHandler(i, btn.slug)
							}}
							key={i}
							className={btn.active ? 'active' : ''}
							href={`#${btn.slug}`}
						>
							<p>{btn.buttonText}</p>
						</Button>
					)
				})}
			</ToggleButtonSection>

			<BtnDetailsSection id={sessionSlug}>
				<BtnDetailsContainer>
					<ButtonDetails slug={sessionSlug} locale={locale}/>
				</BtnDetailsContainer>
			</BtnDetailsSection>
		</>
	)
}

export default ToggleButton
