import React from 'react'
import tw, { css, styled } from 'twin.macro'

const Spin = styled.div`
	.loader,
	.loader:after {
		${tw`rounded-full`}
		${tw`w-24 h-24`}
	}
	.loader {
		${tw`relative text-xs my-12 mx-auto`}
		text-indent: -9999em;
		border-top: 1.1em solid #e3e7ea;
		border-right: 1.1em solid #e3e7ea;
		border-bottom: 1.1em solid #e3e7ea;
		border-left: 1.1em solid #58c8df;
		-webkit-transform: translateZ(0);
		-ms-transform: translateZ(0);
		transform: translateZ(0);
		-webkit-animation: load8 1.1s infinite linear;
		animation: load8 1.1s infinite linear;
	}
	@-webkit-keyframes load8 {
		0% {
			-webkit-transform: rotate(0deg);
			transform: rotate(0deg);
		}
		100% {
			-webkit-transform: rotate(360deg);
			transform: rotate(360deg);
		}
	}
	@keyframes load8 {
		0% {
			-webkit-transform: rotate(0deg);
			transform: rotate(0deg);
		}
		100% {
			-webkit-transform: rotate(360deg);
			transform: rotate(360deg);
		}
	}
`

const Spinner = () => {
	return (
		<Spin>
			<div className={'loader'}>Loading...</div>
		</Spin>
	)
}

export default Spinner
